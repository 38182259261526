@tailwind base;
@tailwind components;
@tailwind utilities;

.table-content-height {
    max-height: calc(100vh - 86px - 130px - 96px - 64px - 16px - 76px);
    min-height: 288px;
  }

.main-content-max-height {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}
  
  /* table filter height: 86 px */
  /* table summary height: 130 px */
  /* page header height: 96 px */
  /* top navbar height: 64 px */
  
  /* space for button and margin at bottom: 76px & 16px */